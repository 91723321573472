<template>
  <div data-test-id="b2b-paused-orders">
    <b2b-layout
      :page-title="t('PAUSED_ORDERS')"
      :heading="t('PAUSED_ORDERS')"
      show-organisation-selector
      :has-tabs="true"
      :enable-all-organisations="true"
      @organisationSelected="setOrganisation($event.detail)"
    >
      <span slot="header">
        <telia-grid>
          <telia-p class="page-info-wrapper">
            {{ t("PAUSED_ORDERS_INFO") }}
          </telia-p>
          <telia-p v-if="isFunctionCustomer" class="page-info-wrapper">
            {{ t("FUNCTION_CUSTOMER_INFO") }}
            <telia-link
              t-id="function-customer-link"
              variant="text"
              :href="getSdwUrl()"
              target="_blank"
              :link-title="t('ALLY.GO_TO_SDW')"
              dark-background
            >
              <telia-icon name="external" slot="right" size="sm" />
              {{ t("SERVICE_DESK_WEB") }}
            </telia-link>
          </telia-p>
        </telia-grid>
      </span>
      <section class="page-content-container">
        <telia-grid>
          <telia-row v-if="!isLoading && hasUserDataError" class="notification-container">
            <telia-col width="6">
              <telia-notification
                t-id="user-info-error-notification"
                variant="inline"
                status="warning"
                heading-tag="h3"
                html-aria-live="polite"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("ALLY.WARNING_STATUS") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ t("ERRORS.USER_INFO") }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
          <telia-row v-else-if="!isLoading && !hasAccess" class="notification-container">
            <telia-col width="6">
              <telia-notification
                t-id="access-error-notification"
                variant="inline"
                status="warning"
                heading-tag="h3"
                html-aria-live="assertive"
                html-role="alert"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("ALLY.WARNING_STATUS") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ t("ERRORS.ACCESS") }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
          <telia-row v-if="!isLoading && hasDeleteOrderError" class="notification-container">
            <telia-col width="6">
              <telia-notification
                t-id="delete-order-error-notification"
                variant="inline"
                status="error"
                heading-tag="h3"
                :button-text="t('ACTIONS.CLOSE')"
                :button-aria-label="t('ALLY.ERROR_STATUS')"
                html-aria-live="assertive"
                html-role="alert"
                @vocaClose="closeDeleteOrderErrorNotification"
              >
                <span slot="heading">
                  <telia-visually-hidden>
                    {{ t("ALLY.ERROR_STATUS") }}
                  </telia-visually-hidden>
                  <telia-p>
                    {{ t("ERRORS.DELETE_ORDER") }}
                  </telia-p>
                </span>
              </telia-notification>
            </telia-col>
          </telia-row>
          <telia-row v-if="hasAccess && !hasUserDataError">
            <telia-col width="12" class="table-container">
              <b2x-table-skeleton
                v-if="isLoading"
                :rows="Math.min(pageSize, defaultPageSize)"
                columns="5"
                show-headers="false"
              ></b2x-table-skeleton>
              <b2x-table-base v-show="!isLoading" t-id="paused-orders-table" class="table">
                <thead>
                  <tr>
                    <th>{{ t("TABLE.COLUMNS.ORDER_ID") }}</th>
                    <th>{{ t("TABLE.COLUMNS.DESCRIPTION") }}</th>
                    <th>{{ t("TABLE.COLUMNS.OWNER") }}</th>
                    <th>{{ t("TABLE.COLUMNS.ORGANISATION") }}</th>
                    <th>{{ t("TABLE.COLUMNS.UPDATED_DATE") }}</th>
                    <th>{{ t("TABLE.COLUMNS.STATUS") }}</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="hasFetchOrdersError">
                    <td colspan="4">
                      <telia-notification
                        t-id="fetch-orders-error-notification"
                        variant="inline"
                        status="warning"
                        heading-tag="h3"
                        html-aria-live="polite"
                        html-role="alert"
                      >
                        <span slot="heading">
                          <telia-visually-hidden>
                            {{ t("ALLY.WARNING_STATUS") }}
                          </telia-visually-hidden>
                          <telia-p>
                            {{ t("ERRORS.PAUSED_ORDERS") }}
                          </telia-p>
                        </span>
                      </telia-notification>
                    </td>
                  </tr>
                  <tr v-else-if="pausedOrderRows.length === 0">
                    <td colspan="8">
                      <telia-visually-hidden>
                        {{ t("ALLY.INFORMATION_STATUS") }}
                      </telia-visually-hidden>
                      <telia-p class="table-information-container">
                        {{ t("TABLE.NO_ORDERS") }}
                      </telia-p>
                    </td>
                  </tr>
                  <tr
                    v-for="(orderRow, orderRowIndex) in pausedOrderRowsForCurrentPage"
                    :key="orderRowIndex"
                    class="table-row"
                  >
                    <td class="table-row-column" :title="orderRow.orderCaptureId">
                      {{ orderRow.orderCaptureId }}
                    </td>
                    <td class="table-row-column" :title="orderRow.description">
                      {{ orderRow.description }}
                    </td>
                    <td class="table-row-column" :title="orderRow.owner">
                      {{ orderRow.ownerDisplayName }}
                    </td>
                    <td class="table-row-column" :title="orderRow.organisationDisplayName">
                      {{ orderRow.organisationDisplayName }}
                    </td>
                    <td class="table-row-column" :title="orderRow.updatedDate">
                      {{ orderRow.updatedDate }}
                    </td>
                    <td class="table-row-column table-row-column__small" :title="orderRow.status">
                      <telia-badge :variant="orderRow.statusVariant">
                        {{ orderRow.status }}
                      </telia-badge>
                    </td>
                    <td class="table-row-column table-row-column__small">
                      <b2x-sspa-link
                        :t-id="`link-${orderRowIndex}`"
                        :href="getOpenDataNetOrderUrl(orderRow.orderCaptureId)"
                        :link-title="t('ALLY.OPEN_ORDER')"
                      >
                        {{ t("ACTIONS.OPEN") }}
                      </b2x-sspa-link>
                    </td>
                    <td class="table-row-column table-row-column__small">
                      <telia-button
                        v-if="orderRow.isOwner"
                        :t-id="`delete-order-button-${orderRowIndex}`"
                        type="button"
                        variant="destructive"
                        size="sm"
                        :ally-label="t('ALLY.DELETE_BUTTON')"
                        @click="showDeleteOrderModal(orderRow.orderCaptureId)"
                      >
                        {{ t("ACTIONS.DELETE") }}
                      </telia-button>
                    </td>
                  </tr>
                </tbody>
              </b2x-table-base>
              <b2x-paginator
                v-show="pausedOrderRows.length > defaultPageSize"
                :list-length="pausedOrderRows.length"
                :page-sizes="JSON.stringify(pageSizes)"
                :default-page-size="defaultPageSize"
                @paginationChange="
                  (event) => handlePaginationChange(event.detail.page, event.detail.pageSize)
                "
              />
            </telia-col>
          </telia-row>
        </telia-grid>
      </section>
      <modal
        v-if="showDeleteModal"
        modal-id="delete-order-modal"
        t-id="delete-order-modal"
        ref="delete-order-modal"
        :is-open="showDeleteModal"
        @closeModal="closeDeleteOrderModal"
      >
        <template v-slot:modal-body>
          <telia-heading tag="h2" variant="title-200" class="modal-title">
            {{ t("MODAL.CONFIRMATION_TITLE") }}
          </telia-heading>
          <telia-p>
            {{ t("MODAL.CONFIRMATION_TEXT", { orderId: selectedOrderId }) }}
          </telia-p>
        </template>
        <template v-slot:button-footer>
          <div class="modal-button-footer">
            <telia-button
              t-id="delete-order-modal-button"
              variant="destructive"
              class="modal-button"
              :ally-label="t('ALLY.DELETE_BUTTON')"
              :disabled="isDeleting"
              @click="deleteOrder(selectedOrderId)"
            >
              {{ t("MODAL.DELETE") }}
            </telia-button>
            <telia-button
              t-id="close-modal-button"
              variant="tertiary-purple"
              class="modal-button"
              :ally-label="t('ALLY.CANCEL_BUTTON')"
              @click="closeDeleteOrderModal"
            >
              {{ t("MODAL.CANCEL") }}
            </telia-button>
          </div>
        </template>
      </modal>
    </b2b-layout>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "./locale";
import { isSdwTestUrlsEnabled } from "./featureToggles";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpDatanetService, corpScopeInformation } from "@telia/b2b-rest-client";
import modal from "./components/modal.vue";

export default {
  name: "b2b-paused-orders",
  mixins: [translateMixin],

  components: {
    modal,
  },

  data() {
    return {
      isLoading: true,
      isDeleting: false,
      isFunctionCustomer: false,
      hasAccess: false,
      hasUserDataError: false,
      hasFetchOrdersError: false,
      hasDeleteOrderError: false,
      showDeleteModal: false,
      scopeDetails: null,
      userServiceWebs: null,
      scopeId: null,
      organisations: null,
      pausedOrders: [],
      selectedTscid: null,
      selectedOrderId: null,
      orderStatuses: {
        paused: "SAVED",
        shared: "ASSIGNED",
      },
      defaultPageSize: 5,
      pageSizes: [5, 10],
      pageSize: 5,
      page: 1,
    };
  },

  computed: {
    pausedOrderRows() {
      return this.pausedOrders
        ?.map((order) => ({
          organisation: order.tscid,
          orderCaptureId: order.orderCaptureId,
          organisationDisplayName: `${order.organisationName} (${order.organisationNumber})`,
          ownerDisplayName: order.ownerDisplayName,
          isOwner: order.isOwner,
          description: order.alias,
          createdDate: new Date(order.createdDate).toISOString().substring(0, 10),
          updatedDate: new Date(order.updatedDate).toISOString().substring(0, 10),
          status: this.t(`STATUSES.${order.status}`),
          statusVariant: order.status === this.orderStatuses.paused ? "attention" : "information",
          sortDate: order.updatedDate,
        }))
        .filter(
          (order) => this.selectedTscid === "ALL" || order.organisation === this.selectedTscid
        )
        .sort((a, b) => {
          const comparableA = a.sortDate;
          const comparableB = b.sortDate;

          if (comparableA < comparableB) {
            return 1;
          } else if (comparableA > comparableB) {
            return -1;
          } else {
            return 0;
          }
        });
    },

    pausedOrderRowsForCurrentPage() {
      return this.pausedOrderRows?.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      await translateSetup(["mybusiness"]);
    } catch (e) {
      this.hasUserDataError = true;
      this.isLoading = false;
    }
    try {
      this.scopeId = await getScopeIdOrThrow();
      this.scopeDetails = await corpScopeInformation.ScopeControllerService.getScopeDetails(
        this.scopeId
      );
      this.userServiceWebs = await corpScopeInformation.ScopeControllerService.getServiceWebs(
        this.scopeId
      );

      this.organisations = await getOrganizations();

      if (this.organisations.length === 1) {
        this.selectedTscid = this.organisations[0]?.tscid;
      }
    } catch (e) {
      this.hasUserDataError = true;
      this.isLoading = false;
      return;
    }

    try {
      const requiredPermissions = ["VIEW_ORDER", "ORDER", "READ_ORDER_HISTORY"];

      this.hasAccess = requiredPermissions.some((pui) =>
        this.scopeDetails?.scope?.actions?.available.includes(pui)
      );

      if (!this.hasAccess) {
        this.isLoading = false;
        return;
      }

      this.isFunctionCustomer = this.userServiceWebs?.serviceWebs?.includes("SDW");
    } catch (e) {
      this.hasAccess = false;
      this.isLoading = false;
      return;
    }

    try {
      this.pausedOrders = await corpDatanetService.ExternalDatanetControllerService.getPausedOrders(
        this.scopeId
      );
    } catch (e) {
      this.hasFetchOrdersError = true;
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
  },

  methods: {
    setOrganisation(value) {
      this.selectedTscid = value;
    },

    getOpenDataNetOrderUrl(orderId) {
      return `/foretag/mybusiness/${this.scopeId}/bestall/datanet/oc/${orderId}`;
    },

    getSdwUrl() {
      let url = "https://servicedeskweb.telia.se/SSO/MyBusinessLogin.aspx";

      if (isSdwTestUrlsEnabled()) {
        url = "https://servicedeskweb-at1.teliasonera.net/SSO/MyBusinessLogin.aspx";
      }

      return url;
    },

    handlePaginationChange(page, pageSize) {
      this.page = page;
      this.pageSize = pageSize;
    },

    async deleteOrder(orderId) {
      this.isDeleting = true;
      this.hasDeleteOrderError = false;

      try {
        await corpDatanetService.ExternalDatanetControllerService.deleteOrderCapture1(
          this.scopeId,
          orderId
        );
        const index = this.pausedOrders.findIndex((order) => order.orderCaptureId === orderId);
        this.pausedOrders.splice(index, 1);
      } catch (e) {
        this.hasDeleteOrderError = true;
      }

      this.isDeleting = false;
      this.closeDeleteOrderModal();
    },

    showDeleteOrderModal(orderId) {
      this.selectedOrderId = orderId;
      this.showDeleteModal = true;
    },

    closeDeleteOrderModal() {
      document.body.style.overflowY = "initial";
      this.selectedOrderId = null;
      this.showDeleteModal = false;
    },

    closeDeleteOrderErrorNotification() {
      this.hasDeleteOrderError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/breakpoints/variables";
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.page-header-container {
  background-color: $telia-gray-50;
  padding: $telia-spacing-32 0;
}

.page-content-container {
  padding: $telia-spacing-32 0 $telia-spacing-48;
}
.page-title-wrapper {
  padding: $telia-spacing-32 0;
}

.org-selector {
  padding: $telia-spacing-12 0;
}

.notification-container {
  margin-bottom: $telia-spacing-32;
}

.table-container {
  overflow-x: scroll;
}

.table-information-container {
  text-align: center;
}

.table-row-column {
  white-space: normal;

  &__small {
    white-space: nowrap;
  }
}

.organisation-select-skeleton {
  height: 4.2rem;
  width: 42rem;
}

.modal-title {
  margin-bottom: $telia-spacing-16;
}

.modal-button-footer {
  padding: $telia-spacing-4 $telia-spacing-16 $telia-spacing-16 $telia-spacing-16;

  @media (min-width: $telia-breakpoint-medium) {
    padding: $telia-spacing-4 $telia-spacing-32 $telia-spacing-32 $telia-spacing-32;
  }

  .modal-button:first-child {
    padding-right: $telia-spacing-16;
  }
}
</style>
