import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

const toggles = {
  "sdw-test-urls": false,
};

function isEnabled(toggleName) {
  return featureToggleIsEnabled(toggleName, toggles);
}

export const isSdwTestUrlsEnabled = () => isEnabled("sdw-test-urls");
